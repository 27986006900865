import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c11852a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-static flex-column" }
const _hoisted_2 = { class: "subscriber--name" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "subscriber--tariff" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.referral, (subscriber, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "subscriber flex items-center",
        key: subscriber._id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(index + 1) + ". " + _toDisplayString(subscriber.name), 1),
          _createElementVNode("a", {
            href: `https://t.me/${subscriber.login}`
          }, " @" + _toDisplayString(subscriber.login), 9, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(subscriber.tariff?.name), 1)
      ]))
    }), 128))
  ]))
}